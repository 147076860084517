/*jshint browser:true, undef: true, unused: true, jquery: true */
$(function () {
	"use strict";
	var $container,
		$featuredContainer,
		$currentContainer,
		totalRecords = '',
		resultText = '',
		pages,
		filters = {},
		keywordValue = '',
		pageSizeValue = 8, // number of items to pull back on filtered results page load and/or subsequent view more
		sort = 11, //default page sort by date (this will change for keyword search)
		pageNum = 1,
		getContentType = 'featured',
		featuredItem = [],
		currentItems = [],
		items = [],
		featuredItemCount = 0,
		currentItemCount = 0,
		itemCount = 0,
		featuredStoryTermId = [],
		currentStoryTermId = [],
		nonCurrentStoryTermId = [],
		relatedTermIds = [],
		defaultTermIds = [], // used for resetting term ids array on keyword search
		outputFacetIds = '', //used for pulling in dynamic filters by these other facets
		searchTermIds = ''; // used for pulling in dynamic filters (this cannot be an array, otherwise it will not pull in taxonomy only used on pages)

	// TAXONOMY IDs
	relatedTermIds = ["a777c21e-644f-ea11-a830-000d3a619f08"], // 'Pulse' term ID from 'Pulse Story' facet
		defaultTermIds = ["a777c21e-644f-ea11-a830-000d3a619f08"], // 'Pulse' term ID from 'Pulse Story' facet
		searchTermIds = 'a777c21e-644f-ea11-a830-000d3a619f08', // 'Pulse' term ID from 'Pulse Story' facet
		outputFacetIds = 'dc49e01d-935d-4ecc-b8ee-5eae6570acb5,19c59a08-07d3-4e7c-ac51-7a274a8aabd5,7fae5929-3d21-450f-b8d1-c56378a69d31,51c209a5-1b66-422e-a0c2-56688acf0268';

	if (window.location.href.startsWith('https://www.cchcpulse.com/')) {//LIVE
		featuredStoryTermId = ["6004782f-0957-ee11-a865-000d3a611c21"];
		currentStoryTermId = ["bce281f8-8a25-ee11-a863-000d3a611c21"];
		nonCurrentStoryTermId = ["9803782f-0957-ee11-a865-000d3a611c21"];
	} else if (window.location.href.startsWith('https://cchcpulse.stage')) {//STAGE
		featuredStoryTermId = ["6004782f-0957-ee11-a865-000d3a611c21"];
		currentStoryTermId = ["bce281f8-8a25-ee11-a863-000d3a611c21"];
		nonCurrentStoryTermId = ["9803782f-0957-ee11-a865-000d3a611c21"];
	} else {//DEV
		featuredStoryTermId = ["3aeb16e8-0757-ee11-8457-a04a5eb7a780"];
		currentStoryTermId = ["2b4357b5-8a25-ee11-87dd-a04a5eb73277"];
		nonCurrentStoryTermId = ["37eb16e8-0757-ee11-8457-a04a5eb7a780"];
	}

	//relatedTermIds, defaultTermIds & searchTermIds should all be the term id of the 'Pulse' term ID from 'Pulse Story' facet. Needed for filtered results.
	//outputFacetIds should be the facet ids of the filter options (hidden or visible), 'Pulse Theme', 'Pulse Topic', 'Puse Author' and 'Pulse Edition' facets.

	$(function () {
		// SETTING VARIABLES
		$container = $('#container');
		$featuredContainer = $('#featured');
		$currentContainer = $('#current-container');

		// ADDING ARIA ATTRIBUTES TO CONTAINER FOR ACCESSIBILITY
		$container.attr('aria-live', 'polite');
		$container.attr('aria-role', 'region');
		$container.attr('aria-relevant', 'additions removals');
		$currentContainer.attr('aria-live', 'polite');
		$currentContainer.attr('aria-role', 'region');
		$currentContainer.attr('aria-relevant', 'additions removals');

		// // SETTING PLACEHOLDER ON CONTENT SEARCH
		// $('#ContentSearchField').attr('placeholder', 'Search Pulse');
		// $('#ContentSearchField').attr('onfocus', 'this.placeholder = ""');
		// $('#ContentSearchField').attr('onblur', 'this.placeholder = "Search Pulse"');

		// LOAD FILTER TAXONOMY TERMS TO CREATE THE CHECKBOXES
		loadFilters();

		// CHECKING FOR HASH IN URL
		if (window.location.hash) {
			// WAIT TILL FILTERS HAVE LOADED
			var checkExist = setInterval(function () {
				if ($('.PulseEdition .option-set .FilterCloseButton').length > 0) {

					var hashValue = window.location.hash.replace('#', ''),
						inputHashElement = hashValue.split('+'),
						inputElement,
						inputElementArray = [];

					// LOOPING THROUGH EACH HASH TAG AS A CLASS ON INPUTS TO SEE IF THEY EXIST AND IF SO ADD THEM TO ARRAY 
					$.each(inputHashElement, function (i, elm) {
						inputElement = '.option-set input.' + elm;
						if ($(inputElement).length > 0) {
							inputElementArray.push(inputElement);
						}
					});

					// TAKE ARRAY OF HASH INPUTS AND CHECK APPROPRIATE CHECKBOXES ON FILTERS & ADD APPROPRIATE TERM IDS TO relatedTermIds ARRAY FOR THE PAGE API & CREATE FILTER CLOSE BUTTONS
					if ($(inputElementArray).length > 0) {
						relatedTermIds = defaultTermIds.slice();
						var checkboxFunction = function () {
							return $.each(inputElementArray, function (i, elm) {
								manageMultipleCheckboxes($(elm));
							});
						};
						$.when(checkboxFunction()).done(function () {
							// AFTER PREVIOUS FUNCTION IS COMPLETE - LOAD FILTERED CONTENT
							getContentType = 'filteredresults';
							pageSizeValue = 8;
							getContent(getContentType, relatedTermIds, keywordValue, sort, pageSizeValue, pageNum);
							runAtPageLoad();
							$('#featured-cta').addClass('NoFeatureDisplay');
							$('#cta').hide();
						});
					}
					clearInterval(checkExist);
				}
			}, 100); // check every 100ms
		} else {
			// LOAD INITIAL CONTENT (FIRST GET CONTENT FOR FEATURED THEN CURRENT STORIES - VIEW MORE BUTTON GETS ADDED IN GET CONTENT FUNCTION)
			relatedTermIds = featuredStoryTermId;
			pageSizeValue = 1;
			getContent(getContentType, relatedTermIds, keywordValue, sort, pageSizeValue, pageNum);

			var checkExist2 = setInterval(function () {
				if (!$('.Loading').length) { // IF LOADING HAS BEEN REMOVED THEN FIRST FEATURED CONTENT HAS BEEN CHECKED FOR
					relatedTermIds = [currentStoryTermId];
					pageSizeValue = 100;
					getContentType = 'currentstories',
						getContent(getContentType, relatedTermIds, keywordValue, sort, pageSizeValue, pageNum);
					clearInterval(checkExist2);
				}
			}, 100); // check every 100ms
		}
	});

	// RUN AT PAGE LOAD
	//====================================================
	function runAtPageLoad() {
		$(window).ajaxComplete(function () {
			var checkExist = setInterval(function () { //WAITING UNTIL CONTENT EXISTS FROM API BEFORE RUNNING ADDITIONAL CHANGES
				if ($('#container .item').length) {   //FIND ITEMS
					$('#TotalResults').remove();

					if (window.location.hash) {
						$('#filter-display').prepend('<div id="TotalResults" aria-live="polite">' + totalRecords + resultText + ' when filtering by</div>');
						$('#container').removeAttr('class');
						$('#container').addClass('ItemTotal' + totalRecords);
						removeCTA();
					}

					clearInterval(checkExist);
				}
			}, 100); // check every 100ms
		});
	}

	// RUN AFTER PAGE LOAD (NEW RESULTS)
	//====================================================
	function runAfterPageLoad() {
		$(window).ajaxComplete(function () {
			var checkExist2 = setInterval(function () {  //WAITING UNTIL CONTENT EXISTS FROM API BEFORE RUNNING ADDITIONAL CHANGES
				if ($('#container .item').length) {   //FIND ITEMS
					$('#TotalResults, #SearchedBy').remove();
					if (getContentType === 'filteredresults') {
						if ($('.FilterItem').length > 0) {
							$('#filter-display').prepend('<div id="TotalResults" aria-live="polite">' + totalRecords + resultText + ' when filtering by</div>');
						} else if (keywordValue.length > 0) {
							$('#filter-display').prepend('<div id="TotalResults" aria-live="polite">' + totalRecords + resultText + ' when searching for <span class="Keywords">"' + keywordValue + '".</span></div>');
						} else {
							$('#filter-display').prepend('<div id="TotalResults" aria-live="polite">' + totalRecords + resultText + '.</div>');
						}
						$('#container').removeAttr('class');
						$('#container').addClass('ItemTotal' + totalRecords);
					}
					clearInterval(checkExist2);
				}
			}, 100); // check every 100ms
		});
	}

	// PULSE FEATURED CONTENT MARKUP
	//====================================================
	var featuredCard = function (page) {
		var element = "<div class='featuredItem " + page.pulseTheme + " " + page.pulseTopic + " " + page.pulseAuthor + " " + page.pulseEdition + "' id='" + page.pageid + "'>" +
			"<h2 class='Overlay'><span><span class='nobr'>Featured Story</span></span></h2>" +
			"<div class='ContentWrap'>" +
			"<a href='" + page.url + "'>" +
			"<span class='ImageWrap'>" + page.image + "</span>" +
			"<span class='ItemWrapRight'>" +
			"<span class='ItemWrapTop'>" +
			"<span class='ThemeWrap'>" + page.pulseThemeTag + "</span>" +
			"<span class='Title'>" + page.title + "</span>" +
			"<span class='Description'>" + page.snippet + " <span class='ReadMore'>Read More</span></span>" +
			"</span>" +
			"</span>" +
			"</a>" +
			"</div>" +
			"</div>";
		return element;
	};

	// Pulse CONTENT MARKUP
	//====================================================
	var cards = function (page) {
		var element = "<div class='item " + page.pulseTheme + " " + page.pulseTopic + " " + page.pulseAuthor + " " + page.pulseEdition + "' id='" + page.pageid + "'>" +
			"<div class='ContentWrap'>" +
			"<a href='" + page.url + "'>" +
			"<span class='ItemWrapTop'>" +
			"<span class='ImageWrap'>" + page.image + "</span>" +
			"<span class='ThemeWrap'>" + page.pulseThemeTag + "</span>" +
			"<span class='Title'>" + page.title + "</span>" +
			"<span class='Description'>" + page.snippet + " <span class='ReadMore'>Read More</span></span>" +
			"</span>" +
			"</a>" +
			"</div>" +
			"</div>";
		return element;
	};

	// BUILD OUT FILTERS DYNAMICALLY AND THEN ALLOW THE DROPDOWNS, FILTERS AND SEARCH TO FUNCTION
	//====================================================
	function loadFilters() {
		const apiPath = `/api/taxonomy/facetandobjects/`;
		const objectTypeCode = 19;
		fetch(`${apiPath}${objectTypeCode}?SearchTermIds=${searchTermIds}&OutputFacetIds=${outputFacetIds}`)
			.then(res => res.json())
			.then(data => {
				buildFilterMarkup(data);
				filterDropdowns();
				filterCheckboxes();
				keywordSearch();
				filterCloseButtons();
				removeCTA()
				$('.LoadingFilters').remove();
			})
			.catch(console.error);
	}

	// CREATE MARKUP FOR THE FILTERS
	//====================================================
	function buildFilterMarkup(resBody) {
		// the order of the array "facetsToFilter" determines the order they are output to the DOM
		const facetsToFilter = ['Pulse Theme', 'Pulse Topic', 'Pulse Author', 'Pulse Edition'];
		if (resBody.Facets.length > 0) {
			let facetMarkup = resBody.Facets
				.filter(facet => facetsToFilter.includes(facet.FacetName))
				.map(facet => ({
					name: facet.FacetName,
					html: buildFacetMarkup(facet)
				}));

			let html = `` +
				`<div id="filter-search">` +
				`<div id="filter">` +
				`<div id="options">` +
				`<p>Filter by</p>` +
				`<div id="filter-groups" class="ClearFix">` +
				``;

			// the order of the array "facetsToFilter" determines the order they are output to the DOM
			facetsToFilter.forEach(facetName => {
				facetMarkup.forEach(facetObject => {
					if (facetObject.name === facetName) {
						html += facetObject.html;
					}
				});
			});

			html += `` +
				`</div>` +
				`</div>` +
				`</div>` +
				`<div id="ContentSearchWrapper">` +
				`<div id="Or">or</div>` +
				`<div id="ContentSearch">` +
				`<label class="Hide" for="ContentSearchField">Search Pulse</label>` +
				`<input id="ContentSearchField" maxlength="128" name="term" type="search" value="" placeholder="Search Pulse" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Search Pulse">` +
				`<input alt="Search" class="ImgButton" src="/imgs/icons/Search-Icon-Black.svg" type="image">` +
				`</div>` +
				`</div>` +
				`</div>`;

			let queryFilterSearch = document.querySelector('#filterSearchWrapper');
			queryFilterSearch.innerHTML += html;

			// SETTING PLACEHOLDER ON CONTENT SEARCH
			$('#ContentSearchField').attr('placeholder', 'Search Pulse');
			$('#ContentSearchField').attr('onfocus', 'this.placeholder = ""');
			$('#ContentSearchField').attr('onblur', 'this.placeholder = "Search Pulse"');
		} else {
			console.info(`Pulse Hub - no facets found`);
		}
	}

	// CREATE INDIVIDUAL FACET MARKUP FOR THE FILTERS
	//====================================================
	function buildFacetMarkup(facet) {
		let facetDisplayName, facetDisplayNamePlural;
		switch (facet.FacetPropertyName) {
			case 'PulseTheme':
				facetDisplayName = 'Theme';
				facetDisplayNamePlural = 'Themes';
				break;
			case 'PulseTopic':
				facetDisplayName = 'Topic';
				facetDisplayNamePlural = 'Topics';
				break;
			case 'ServiceLine':
				facetDisplayName = 'Service';
				facetDisplayNamePlural = 'Services';
				break;
			case 'PulseAuthor':
				facetDisplayName = 'Author';
				facetDisplayNamePlural = 'Authors';
				break;
			default:
				break;
		}

		let termsMarkup = ``;
		if (facet.Terms.length > 0) {
			termsMarkup = buildTermsMarkup(facet, facetDisplayNamePlural);
		}

		const hiddenFilters = ['PulseAuthor', 'PulseEdition'];
		return `` +
			`${hiddenFilters.includes(facet.FacetPropertyName) ? `<div style="display:none;">` : ``}` +
			`${['PulseTopic', 'PulseAuthor', 'PulseEdition'].includes(facet.FacetPropertyName) ? `<p>and/or</p>` : ``}` +
			`<div class="${facet.FacetPropertyName}">` +
			`<button class="FilterButton" id="${facet.FacetPropertyName}">${facetDisplayName}<span></span></button>` +
			termsMarkup +
			`</div>` +
			`${hiddenFilters.includes(facet.FacetPropertyName) ? `</div>` : ``}` + ``;
	}

	// CREATE INDIVIDUAL TERM MARKUP FOR THE FACET FILTERS
	//====================================================
	function buildTermsMarkup(facet, pluralName) {
		let html = `` +
			`<fieldset class="option-set" data-group="${facet.FacetPropertyName}">` +
			`<legend class="option-set-header ClearFix">${pluralName}</legend>` +
			`<div class="option-set-contents">` +
			`<label for="${facet.FacetPropertyName}-all"><input checked="checked" class="all" id="${facet.FacetPropertyName}-all" type="checkbox" value=""><span class="checkmark"></span> All ${pluralName}</label>` +
			``;

		facet.Terms.forEach(term => {
			if (term.Name != "_No Topic") {
				let termNameClass = term.Name.replace(/[^0-9a-zA-Z]+/g, '');
				html += `<label for="${term.TermId}" ${term.Distance !== 0 ? 'class="ChildTerm"' : ''}><input id="${term.TermId}" class="${termNameClass}" type="checkbox" value="${term.TermId}" /><span class="checkmark"></span> ${term.Name}</label>`;
			}
		});

		html += `` +
			`</div>` +
			`<button class="FilterCloseButton">Close</button>` +
			`</fieldset>` +
			`<div class="FadeOverlay"></div>` +
			``;

		return html;
	}

	// DROP DOWN BUTTON FUNCTIONALITY
	//====================================================
	function filterDropdowns() {
		$('#options button.FilterButton').on('click', function (e) {
			e.preventDefault();
			if ($(this).hasClass('Active')) {
				//remove active class to close dropdown on clicked button if was active
				$(this).removeClass('Active');
				$(this).next('.option-set').removeClass('Active');
				$('body').removeClass('NoScroll');
				$('.modal-overlay').remove();
			} else {
				//first remove active class from all in case other drop down is open
				$('#options button.FilterButton').removeClass('Active');
				$('#options button.FilterButton').next('.option-set').removeClass('Active');
				//then open clicked on button dropdown
				$(this).addClass('Active');
				$(this).next('.option-set').addClass('Active');
				$('body').addClass('NoScroll');
				$('.modal-overlay').remove();
				$('<div class="modal-overlay"></div>').insertBefore('#filter-search');
				activateFadeOverlay();
				removeCTA();
			}
		});
	}

	function removeCTA() {

		if ($('.FilterItem').length > 0) {
			$('#cta').hide()

		}
	}

	// DO STUFF ON CHECKBOX CHANGE
	//====================================================
	function filterCheckboxes() {

		$('#options').on('change', function (jQEvent) {
			var $checkbox = $(jQEvent.target);

			//THEN CLEAR OUT ELEMENTS IF THERE
			resetPageElements();

			//RESET TERM IDS TO 'PULSE' TERM IF NO FILTERS HAVE BEEN SELECTED YET
			if (!$('.FilterItem').length) {
				relatedTermIds = defaultTermIds.slice();
			}

			//MANAGING THE CHECKBOXES AND SET NEW VALUES
			manageCheckbox($checkbox);

			// LOAD NEW RESULTS BASED ON TERMS AND APPLY MASONRY
			$('input#ContentSearchField').val('');
			keywordValue = ''; //resetting keyword value to be empty so that it doesn't combine the search functionalities
			sort = 11; //resetting sort value to be based on date
			pageSizeValue = 8;
			getContentType = 'filteredresults';
			getContent(getContentType, relatedTermIds, keywordValue, sort, pageSizeValue, pageNum);
			runAfterPageLoad();
			removeCTA();
			$('#featured-cta').addClass('NoFeatureDisplay');
			$('#cta').hide();
		});
	}

	// DO STUFF ON FILTERED BY BUTTONS TO TURN OFF FILTERS (BY FORCING A CLICK ON ASSOCIATED INPUT IT WILL RUN THE filterCheckboxes FUNCTION)
	//====================================================
	function filterCloseButtons() {
		$('.Pulse.Hub').on('click', '#filter-display button.FilterItem, #filter-display button input', function (e) {
			e.preventDefault();
			$('.option-set input').each(function () {
				if ($(this).attr('value') === $(e.target).attr('data-id')) {
					$(this).trigger('click');
				}
			});

		});
	}

	// DO STUFF ON KEYWORD SEARCH
	//====================================================
	function keywordSearch() {
		$('input#ContentSearchField').each(function () {
			$(this).on('focus', function () {
				keywordValue = $(this).val();
			});
			$(this).on('blur', function () {
				keywordValue = $(this).val();
			});
			// keyword search on enter key
			$(this, 'input.ImgButton').keydown(function (event) {
				if (event.keyCode === 13) {
					event.preventDefault();
					keywordSearchFunc();
				}
			});
			// keyword search on button click
			$('#ContentSearch input.ImgButton').click(function (event) {
				event.preventDefault();
				keywordSearchFunc();
			});
		});
	}
	function keywordSearchFunc() {
		//setting the error text value
		var emptySearch = '<div class="error">Please enter search criteria.</div>';
		//setting sort order to go by relevancy
		sort = 7;
		//setting the keyword value to what's within the input
		keywordValue = $('input#ContentSearchField').val();
		//resetting the relatedTermIds back to the default content type facet terms
		relatedTermIds = defaultTermIds.slice();

		if (keywordValue.length > 0) {
			// remove contents, error message, filtered by buttons, and checkmarks from filters (add checked to all checkmarks)
			resetPageElements();
			$('button.FilterItem').remove();
			$('.option-set input').not('.option-set input.all').prop('checked', false);
			$('.option-set input.all').prop('checked', true);

			// add filtered by text
			$('#filter-display').prepend('<div id="SearchedBy">You have searched for <span class="Keywords">"' + keywordValue + '"</span></div>');
			// pull back content by keyword
			getContentType = 'filteredresults';
			pageSizeValue = 8;
			getContent(getContentType, relatedTermIds, keywordValue, sort, pageSizeValue, pageNum);
			// run masonry on content
			runAfterPageLoad();
			$('#featured-cta').addClass('NoFeatureDisplay');
			$('#cta').hide();
		} else {
			if (!$('#ContentSearch .error').length) {
				$('#ContentSearch').append(emptySearch);
			}
		}
	}

	// PULL IN CONTENT FROM PAGE API - THIS GETS CALLED ON PAGE LOAD, ON KEYWORD SEARCH & ON CHECKBOX FILTERS
	//====================================================
	function getContent(getContentType, relatedTermIds, keywordValue, sort, pageSizeValue, pageNum) {
		$('.option-set-contents label input').attr('disabled', true);
		$('button#ViewMore, #TotalResults').remove();

		$.getCmsPages({
			pageNumber: pageNum,
			pageSize: pageSizeValue,
			sort: sort,
			fields: "PageId, HtmlTitle, publichref, originalPublicationDate, Description, Images, Taxonomy",
			criteria: {
				originalpublicationbegindate: "01/01/2013",
				relatedTermIds: relatedTermIds,
				keyword: keywordValue
			}
		}, function (data) {
			//store totalRecords in variable
			totalRecords = data.TotalRecords;
			pages = Math.ceil(totalRecords / pageSizeValue);
			resultText = totalRecords > 1 ? ' results' : ' result';

			// ADD TOTAL CURRENT STORIES COUNT AS CLASS NAME TO CONTAINER FOR STYLING
			if (getContentType === 'currentstories') {
				$currentContainer.addClass('CurrentCount' + totalRecords);
			}

			for (var i in data.Values) {
				var cmspage = data.Values[i];

				var item = {
					title: cmspage.HtmlTitle,
					pageid: cmspage.PageId,
					url: cmspage.PublicHRef.replace(/~/g, ''),
					newDateFormat: buildPubDate(cmspage),
					snippet: buildSnippet(cmspage),
					imageSrc: (typeof cmspage.Images[0] !== 'undefined') ? "/app/files/public/" + cmspage.Images[0].FileName : "/imgs/Pulse-Thumb.jpg",
					image: (typeof cmspage.Images[0] !== 'undefined') ? "<img alt='' src='/app/files/public/" + cmspage.Images[0].FileName + "'/>" : "<img alt='' src='/imgs/Pulse-Thumb.jpg' />",
					pulseTopic: '',
					eachTopicTermWithNobr: '',
					pulseTopicTag: '',
					pulseTheme: '',
					eachThemeTermWithNobr: '',
					pulseThemeTag: '',
					pulseEdition: '',
					eachEditionTermWithNobr: '',
					pulseEditionTag: '',
					pulseAuthor: '',
					eachAuthorTermWithNobr: '',
					pulseAuthorTag: '',
					topicTerms: [],
					themeTerms: [],
					editionTerms: [],
					authorTerms: []
				};

				for (i in cmspage.Taxonomy) {
					//set variable to taxonomy object
					var Taxonomy = cmspage.Taxonomy[i];

					//check for taxonomy = Pulse Topic
					if (Taxonomy.FacetName === 'Pulse Topic') {
						//loop through taxonomy for terms
						for (i in Taxonomy.Terms) {
							//set variable to terms object
							var topicTerm = Taxonomy.Terms[i];
							//add terms to array
							item.topicTerms.push(topicTerm.Name);
						}
						item.pulseTopic = (item.topicTerms.toString().replace(/\s+/g, '').replace(/,/g, ' ').replace(/[^a-zA-Z 0-9]+/g, ''));
						//(this takes topics from taxonomy and if it's not undefined & it's not marked "No Topic" then set any others to this variable), also add in comma between each Topic term
						item.eachTopicTermWithNobr = "<span class='nobr'>" + (item.topicTerms.toString().replace(/,/g, ',</span> <span class="nobr">')) + "</span>";
						item.pulseTopicTag = (item.topicTerms.toString() !== 'undefined' && item.topicTerms.toString() !== '_No Topic') ? "<span class='Topic'>" + item.eachTopicTermWithNobr + "<span class='Underline'></span></span>" : "";
					}

					//check for taxonomy = Pulse Theme
					if (Taxonomy.FacetName === 'Pulse Theme') {
						//loop through taxonomy for terms
						for (i in Taxonomy.Terms) {
							//set variable to terms object
							var themeTerm = Taxonomy.Terms[i];
							//add terms to array
							item.themeTerms.push(themeTerm.Name);
						}
						item.pulseTheme = (item.themeTerms.toString().replace(/\s+/g, '').replace(/,/g, ' ').replace(/[^a-zA-Z 0-9]+/g, ''));
						//(this takes themes from taxonomy and if it's not undefined & it's not marked "No Theme" then set any others to this variable), also add in comma between each Theme term
						item.eachThemeTermWithNobr = "<span class='nobr'>" + (item.themeTerms.toString().replace(/,/g, ',</span> <span class="nobr">')) + "</span>";
						item.pulseThemeTag = (item.themeTerms.toString() !== 'undefined' && item.themeTerms.toString() !== '_No Theme') ? "<span class='Theme'>" + item.eachThemeTermWithNobr + "</span>" : "";
					}

					//check for taxonomy = Pulse Edition
					if (Taxonomy.FacetName === 'Pulse Edition') {
						//loop through taxonomy for terms
						for (i in Taxonomy.Terms) {
							//set variable to terms object
							var editionTerm = Taxonomy.Terms[i];
							//add terms to array
							item.editionTerms.push(editionTerm.Name);
						}
						item.pulseEdition = (item.editionTerms.toString().replace(/\s+/g, '').replace(/,/g, ' ').replace(/[^a-zA-Z 0-9]+/g, ''));
						//(this takes edition from taxonomy and if it's not undefined & it's not marked "No Edition" then set any others to this variable), also add in comma between each Edition term
						item.eachEditionTermWithNobr = "<span class='nobr'>" + (item.editionTerms.toString().replace(/,/g, ',</span> <span class="nobr">')) + "</span>";
						item.pulseEditionTag = (item.editionTerms.toString() !== 'undefined' && item.editionTerms.toString() !== '_No Edition') ? "<span class='Edition'>" + item.eachEditionTermWithNobr + "</span>" : "";
					}

					//check for taxonomy = Pulse Author
					if (Taxonomy.FacetName === 'Pulse Author') {
						//loop through taxonomy for terms
						for (i in Taxonomy.Terms) {
							//set variable to terms object
							var authorTerm = Taxonomy.Terms[i];
							//add terms to array
							item.authorTerms.push(authorTerm.Name);
						}
						item.pulseAuthor = (item.authorTerms.toString().replace(/\s+/g, '').replace(/,/g, ' ').replace(/[^a-zA-Z 0-9]+/g, ''));
						//(this takes author from taxonomy and if it's not undefined & it's not marked "No Author" then set any others to this variable), also add in comma between each Author term
						item.eachAuthorTermWithNobr = "<span class='nobr'>" + (item.authorTerms.toString().replace(/,/g, ',</span> <span class="nobr">')) + "</span>";
						item.pulseAuthorTag = (item.authorTerms.toString() !== 'undefined' && item.authorTerms.toString() !== '_No Author') ? "<span class='Author'>" + item.eachAuthorTermWithNobr + "</span>" : "";
					}
				} //end taxonomy loop

				//IF THERE ARE RESULTS & THEY ARE FEATURED RESULTS THEN BUILD OUT BASED ON CARDS DATA
				if (data.Values.length > 0 && getContentType === 'featured') {
					featuredItem.push(item);

					for (featuredItemCount; featuredItemCount < pageSizeValue * pageNum && featuredItemCount < featuredItem.length; featuredItemCount++) {
						var featuredcardsData = featuredCard(featuredItem[featuredItemCount]);
						$featuredContainer.append(featuredcardsData);
					}
				}

				//IF THERE ARE RESULTS & THEY ARE CURRENT STORIES RESULTS THEN BUILD OUT BASED ON CARDS DATA
				if (data.Values.length > 0 && getContentType === 'currentstories') {
					currentItems.push(item);

					//IF THERE ARE RESULTS THEN BUILD OUT BASED ON CARDS DATA
					for (currentItemCount; currentItemCount < pageSizeValue * pageNum && currentItemCount < currentItems.length; currentItemCount++) {
						var currentCardsData = cards(currentItems[currentItemCount]);
						$currentContainer.append(currentCardsData);
					}
				}

				//IF THERE ARE RESULTS & THEY ARE NON-CURRENT STORIES RESULTS THEN BUILD OUT BASED ON CARDS DATA
				if (data.Values.length > 0 && getContentType === 'noncurrentstories') {
					items.push(item);

					//IF THERE ARE RESULTS THEN BUILD OUT BASED ON CARDS DATA
					for (itemCount; itemCount < pageSizeValue * pageNum && itemCount < items.length; itemCount++) {
						var cardsData = cards(items[itemCount]);
						$container.append(cardsData);
					}
				}

				//IF THERE ARE RESULTS & THEY ARE FILTERED RESULTS THEN BUILD OUT BASED ON CARDS DATA
				if (data.Values.length > 0 && getContentType === 'filteredresults') {
					var cardResultsData = cards(item);
					$container.append(cardResultsData);
				}

			} //DONE LOOPING THROUGH INDIVIDUAL RESULTS

			if (getContentType === 'noncurrentstories' || getContentType === 'filteredresults' || getContentType === 'currentstories') {
				//IF THERE ARE NO RESULTS THEN GIVE THE NO RESULTS MESSAGE
				if (totalRecords === 0) {
					$('#filter-display').append('<div class="NoResults"><p>Sorry, no results were found.</p></div>');
				}

				//ADDING THE VIEW MORE BUTTON IF MORE THAN SET NUMBER OF RESULTS OR JUST PULLED ALL CURRENT STORIES
				if ((pageNum < pages && pages > 1) || getContentType === 'currentstories') {
					$('#containerWrap').append('<button id="ViewMore">More Stories</button>');
				}
			}

			$('button#ViewMore').click(function () {
				// ONCE WE'VE GOTTEN THE FIRST RESULTS FROM CURRENT STORIES, RESET
				if (getContentType === 'currentstories') {
					getContentType = 'noncurrentstories';
					relatedTermIds = nonCurrentStoryTermId;
					pageSizeValue = 8;
					pageNum = 1;
				} else { // filtered results or non-current stories
					pageNum++;
				}
				getContent(getContentType, relatedTermIds, keywordValue, sort, pageSizeValue, pageNum);
				runAfterPageLoad();
			});

			$('.Loading').remove();
			$('.option-set-contents label input').attr('disabled', false);
		});
	}

	// MANAGE THE CHECKBOXES (IF LAST CHECKBOX IS UNCHECKED THEN AUTO CHECK THE ALL CHECKBOX OR IF ALL IS SELECTED UNCHECK ALL OTHER CHECKBOXES) 
	// THIS ALSO DEFINES THE UPDATED VALUES OF THE RELATEDTERMIDS TO RUN THE API WITH
	//====================================================
	function manageCheckbox($checkbox) {

		var checkbox = $checkbox[0],
			checkboxLabel = $checkbox.parents('label'),
			otherCBLabelsInGroup = checkboxLabel.siblings('label'),
			group = $checkbox.parents('.option-set').attr('data-group'),
			isAll = $checkbox.hasClass('all');

		//OPTIONS FOR FILTERED BY AREA
		var checkboxText = $(checkboxLabel).text(),
			filterIdClass = $(checkboxLabel).attr('for');

		// CREATE ARRAY FOR FILTER GROUP, IF NOT THERE YET
		var filterGroup = filters[group];
		if (!filterGroup) {
			filterGroup = filters[group] = [];
		}

		// INDEX OF (KEEPS TRACK OF PLACEMENT WITHIN THE ARRAY)
		var index = $.inArray(checkbox.value, filterGroup);

		// CHECKING THE ALL CHECKBOX WITHIN A GROUP
		if (isAll) {

			//remove all filters from the group
			delete filters[group];

			//if checkmark is not checked then check it
			if (!isAll.checked) {
				checkbox.checked = 'checked';
			}

			//removing all other related terms from the group from the relatedTermIds criteria to pull back content
			relatedTermIds = relatedTermIds.filter(function (el) {
				return filterGroup.indexOf(el) < 0;
			});

			//remove filtered by buttons for individual group when you check all
			$('button.FilterItem').each(function () {
				if ($.inArray($(this).attr('data-id'), filterGroup) !== -1) {
					$(this).remove();
				}
			});
		}

		// CHECKING ANY CHECKBOX WITHIN A GROUP, EXCEPT THE ALL CHECKBOX
		if (checkbox.checked) {
			var selector = isAll ? 'input' : 'input.all';

			//remove check from all checkbox
			$(otherCBLabelsInGroup).children(selector).removeAttr('checked');

			if (!isAll && index === -1) {
				// add filter to group
				filters[group].push(checkbox.value);
				relatedTermIds.push(checkbox.value);

				//build out filtered by area
				$('#filter-display').prepend('<button class="FilterItem" data-id="' + filterIdClass + '"><span class="Hide">Remove </span>' + checkboxText + '<span class="Hide"> filter</span><span class="CloseIcon" aria-hidden="true"></span></button></span>');
			}
		}

		// UNCHECKING A CHECKMARK WITHIN A GROUP, EXCEPT THE ALL CHECKBOX
		else if (!isAll) {
			// remove filter from group
			filters[group].splice(index, 1);
			if ((index = relatedTermIds.indexOf(checkbox.value)) !== -1) {
				relatedTermIds.splice(index, 1);
			}
			// if unchecked the last box, check the all
			if (!$(otherCBLabelsInGroup).children().is(":checked")) {
				$(otherCBLabelsInGroup).children('input.all').attr('checked', 'checked');
			}
			//removing filtered by buttons when unchecked from drop down
			$('button.FilterItem').each(function () {
				if ($(this).attr('data-id') === checkbox.value) {
					$(this).remove();
				}
			});
		}
	}

	// MANAGE MULTIPLE CHECKBOXES (THIS OCCURS WHEN HASH TAGS ARE ADDED TO THE URL) 
	// THIS ALSO DEFINES THE UPDATED VALUES OF THE RELATEDTERMIDS TO RUN THE API WITH
	//====================================================
	function manageMultipleCheckboxes($checkbox) {

		var checkbox = $checkbox[0],
			checkboxLabel = $checkbox.parents('label'),
			otherCBLabelsInGroup = checkboxLabel.siblings('label'),
			group = $checkbox.parents('.option-set').attr('data-group'),
			isAll = $checkbox.hasClass('all');

		//OPTIONS FOR FILTERED BY AREA
		var checkboxText = $(checkboxLabel).text(),
			filterIdClass = $(checkboxLabel).attr('for');

		// CREATE ARRAY FOR FILTER GROUP, IF NOT THERE YET
		var filterGroup = filters[group];
		if (!filterGroup) {
			filterGroup = filters[group] = [];
		}

		// INDEX OF (KEEPS TRACK OF PLACEMENT WITHIN THE ARRAY)
		var index = $.inArray(checkbox.value, filterGroup);

		var selector = isAll ? 'input' : 'input.all';
		//since there is not click we need to force the active checked state
		$checkbox.attr('checked', 'checked');

		//remove check from all checkbox
		$(otherCBLabelsInGroup).children(selector).removeAttr('checked');

		if (!isAll && index === -1) {
			// add filter to group
			filters[group].push(checkbox.value);
			relatedTermIds.push(checkbox.value);
			//build out filtered by area
			$('#filter-display').prepend('<button class="FilterItem" data-id="' + filterIdClass + '"><span class="Hide">Remove </span>' + checkboxText + '<span class="Hide"> filter</span><span class="CloseIcon" aria-hidden="true"></span></button></span>');
		}
	}

	// ON RESIZE - ACTIVATE FADE OVERLAY ON FILTER LIGHTBOX FUNCTION
	//===================================================
	$(window).resize(function () {
		activateFadeOverlay();
	});

	//ADDING CLASS TO FADE OVERLAY ON FILTER LIGHTBOX ONLY WHEN SCROLL BAR IS THERE
	//====================================================
	function activateFadeOverlay() {
		if ($('.option-set.Active').length > 0) {
			var activeOptionSet = ($('.option-set.Active').outerHeight());
			var activeOptionSetContents = ($('.option-set.Active .option-set-header').outerHeight() + $('.option-set.Active .option-set-contents').outerHeight() + 3);

			if (activeOptionSet < activeOptionSetContents) {
				$('.FadeOverlay').addClass('Active');
			}
			else {
				$('.FadeOverlay').removeClass('Active');
			}
		}
	}

	//SEARCH OR FILTER RESETS - REMOVING OR UPDATING ELEMENTS WHEN NEW SEARCH OR FILTER OCCURS
	//====================================================
	function resetPageElements() {
		$('#SearchedBy, .NoResults, #ContentSearch .error, #containerWrap button#ViewMore, .featuredItem').remove();
		$container.find('.item').remove();
		$container.css('height', 'auto');
		$currentContainer.find('.item').remove();
		$currentContainer.removeAttr('class');
	}

	// ANY CLICK OUTSIDE OF THE FILTERS LIGHTBOX AREA WILL CLOSE AN ACTIVE FILTER OPTIONS BOX
	//====================================================
	$(document).on('click', function (e) {
		if ($('#filter').has(e.target).length === 0) {
			$($('#filter .option-set.Active').prev('#filter button.Active')).trigger('click');
		}
	});

	// CLICKING ON THE X ON THE FILTER BOX WILL CLOSE ACTIVE FILTER OPTIONS BOX
	//====================================================
	$('.Pulse.Hub').on('click', 'button.FilterCloseButton', function (ele) {
		ele.preventDefault();
		$($('#filter .option-set.Active').prev('#filter button.Active')).trigger('click');
	});

	// CLICKING ON AUTHORS WITHIN THE ITEM CONTENT BLOCKS 
	//(since it stays on the same page we need to clear any previous filter and trigger the filter input, because it doesn't reload the page)
	//====================================================
	$('.item a.Author').on('click', function () {
		var authorHashValue = $(this).attr('href').replace('#', ''),
			inputHashElement = '.option-set input.' + authorHashValue;
		if (!$(inputHashElement).is(':checked')) {
			$(inputHashElement).trigger('click');
		}
	});

	// BUILD OUT PAGE SNIPPET WITHIN API
	//====================================================
	function buildSnippet(cmspage) {
		var pageContent = cmspage.Description,
			pageParagraphContent = pageContent.substring(pageContent.indexOf("<p>")),
			pageContentText = pageParagraphContent.toString().replace(/(<([^>]+)>)/ig, "");

		return pageContentText.split(/\s+/).slice(0, 21).join(" ") + '<span>... </span>';
	}

	// BUILD OUT PUB DATE
	//====================================================
	function buildPubDate(cmspage) {
		var pubDate = cmspage.OriginalPublicationDate;

		return convertDate(pubDate);
	}

	// REFORMATTING PUBLICATION DATE
	//====================================================
	function convertDate(date) {
		var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
			splitDate = date.split('-');
		return months[parseInt(splitDate[1]) - 1] + " " + splitDate[2] + ", " + splitDate[0];
	}

	// COLLAPSE FILTERS / EXPAND FILTERS
	//====================================================
	$('button.FilterSearchButton').on('click', function () {
		$('#filterSearchWrapper').slideToggle().toggleClass('Open');
		$(this).toggleClass('Open');
		if ($(this).attr('aria-expanded') === 'true') {
			$(this).attr({
				'aria-expanded': 'false'
			});
		} else {
			$(this).attr({
				'aria-expanded': 'true'
			});
		}
		return false;
	});
});